<template>
  <div class="home">
    <WeibullDistrubitionTable></WeibullDistrubitionTable>
  </div>
</template>

<script>
import WeibullDistrubitionTable from "@/components/WeibullDistrubitionTable.vue";
export default {
  name: "WeibullDistribution",
  components: {
    WeibullDistrubitionTable,
  },
};
</script>
